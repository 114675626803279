export const projectTypes = [
    {
      value: "Arts",
      label: "Arts",
    },
    {
      value: "Design",
      label: "Design",
    },
    {
      value: "Tech",
      label: "Tech",
    },
    {
      value: "Food",
      label: "Food",
    },
    {
      value: "Games",
      label: "Games",
    },
    {
      value: "Music",
      label: "Music",
    },
  ];