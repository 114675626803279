import { makeStyles } from "@material-ui/styles";


export const useStyles = makeStyles(() => ({
    sixr_logo: {
      padding: "30px",
      width: "10%",
      margin:"auto"
    },
  }));
